import { COLLECTION_TYPE } from "../../../assets/models/iCollection";
import { NFT_SELL_STATUS } from "../../../assets/models/iNft";
import StayRequest from "../../../assets/TakyonDesignSystem/components/StayRequest/StayRequest";
import TakContainer from "../../../assets/TakyonDesignSystem/components/TakContainer/TakContainer";
import { iWallSlot, WALL_SLOT_TYPE } from "../../../config/wall";
import Search from "./Search";
import Promo from "../../../assets/TakyonDesignSystem/components/Promo/Promo";
import promoBG from "../../../assets/img/wall/promo.jpg";
import promoLogo from "../../../assets/img/wall/promo_logo.png";
import promoBG2 from "../../../assets/img/wall/promo2.jpg";
import promoLogo2 from "../../../assets/img/wall/promo2_logo.jpg";
import promoBG3 from "../../../assets/img/wall/promo3.jpg";
import promoLogo3 from "../../../assets/img/wall/promo3_logo.svg";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import getLink, { LINK_TYPE } from "../../../config/Links";
import { useNavigate } from "react-router-dom";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import VeneziaImg from "../../../assets/img/directsearch/venezia.jpg";
import FirenzeImg from "../../../assets/img/directsearch/firenze.jpg";
import RomaImg from "../../../assets/img/directsearch/roma.jpg";
import MilanoImg from "../../../assets/img/directsearch/milano.jpg";
import AssisiImg from "../../../assets/img/directsearch/assisi.jpg";
import MateraImg from "../../../assets/img/directsearch/matera.jpg";
import SienaImg from "../../../assets/img/directsearch/siena.jpg";
import VeronaImg from "../../../assets/img/directsearch/verona.jpg";
import DiscoverCity, {
  DiscoverCityProps,
} from "../../../assets/TakyonDesignSystem/components/DiscoverCity/DiscoverCity";
import ScrollContainer from "../../../assets/TakyonDesignSystem/components/ScrollContainer/ScrollContainer";
import DiscoverHotel from "../../../assets/TakyonDesignSystem/DiscoverHotel/DiscoverHotel";

export default function Metasearch() {
  const { t } = useTranslation();
  const isShortScreen = useMediaQuery({
    query: "only screen and (min-width : 769px) and (max-height : 600px)",
  });
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const isExperienceTak = {
    "payload.type": COLLECTION_TYPE.experience,
  };
  const mixedTakgetInspired = {
    $or: [
      {
        $and: [
          {
            $or: [
              { "payload.type": { $exists: false } },
              { "payload.type": "hotel" },
              { "payload.type": null },
            ],
          },
          {
            $or: [
              { isHotelFakeSupply: { $exists: false } },
              { isHotelFakeSupply: false },
            ],
          },
        ],
      },
      {
        $and: [
          {
            $or: [
              { "payload.type": { $exists: false } },
              { "payload.type": "hotel" },
              { "payload.type": null },
            ],
          },
          {
            isHotelFakeSupply: true,
          },
        ],
      },
      {
        ...isExperienceTak,
        sellStatus: NFT_SELL_STATUS.FOR_SALE,
      },
    ],
    sellStatus: "FOR_SALE",
  };

  const promo: iWallSlot = {
    title: "",
    config: {
      type: WALL_SLOT_TYPE.PROMO,
      payload: {
        title: "ds.promo_title",
        subtitle: "",
        background: promoBG,
        logo: promoLogo,
        action: "https://www.clubdelsole.com/it",
      },
    },
  };
  const promo2: iWallSlot = {
    title: "",
    config: {
      type: WALL_SLOT_TYPE.PROMO,
      payload: {
        title: "ds.promo_title2",
        subtitle: "",
        background: promoBG2,
        logo: promoLogo2,
        action: "https://vretreats.com/cervino/",
      },
    },
  };
  const promo3: iWallSlot = {
    title: "",
    config: {
      type: WALL_SLOT_TYPE.PROMO,
      payload: {
        title: "ds.promo_title3",
        subtitle: "",
        background: promoBG3,
        logo: promoLogo3,
        action: "https://www.xalphotel.it/",
      },
    },
  };
  const stays: iWallSlot = {
    title: "secondary.stays_title",
    config: {
      type: WALL_SLOT_TYPE.CLASSIC,
      payload: {
        scroll: true,
        query: mixedTakgetInspired,
        sort: { isHotelFakeSupply: 1 },
        limit: 4,
      },
    },
  };
  const stayRequest: iWallSlot = {
    title: "secondary.stay_request_title",
    subtitle: "secondary.experiences_btn",
    config: {
      type: WALL_SLOT_TYPE.STAY_REQUEST,
      payload: {},
    },
  };
  const experiences: iWallSlot = {
    title: "secondary.experiences_title",
    config: {
      type: WALL_SLOT_TYPE.CLASSIC,
      payload: {
        scroll: true,
        query: {
          ...isExperienceTak,
          sellStatus: NFT_SELL_STATUS.FOR_SALE,
        },
        sort: { lockDate: -1 },
        limit: 4,
      },
    },
  };

  const mostWanted: DiscoverCityProps[] = [
    {
      title: t("ds.most_wanted_venezia"),
      action: "Venezia",
      image: VeneziaImg,
    },
    {
      title: t("ds.most_wanted_firenze"),
      action: "Firenze",
      image: FirenzeImg,
    },
    {
      title: t("ds.most_wanted_roma"),
      action: "Roma",
      image: RomaImg,
    },
    {
      title: t("ds.most_wanted_milano"),
      action: "Milano",
      image: MilanoImg,
    },
  ];
  const discoverCity: DiscoverCityProps[] = [
    {
      title: t("ds.most_wanted_matera"),
      action: "Matera",
      image: MateraImg,
    },
    {
      title: t("ds.most_wanted_verona"),
      action: "Verona",
      image: VeronaImg,
    },
    {
      title: t("ds.most_wanted_assisi"),
      action: "Assisi",
      image: AssisiImg,
    },
    {
      title: t("ds.most_wanted_siena"),
      action: "Siena",
      image: SienaImg,
    },
  ];

  const bestHotels: string[] = [
    "93608bb8-ddb1-4bfb-8089-245e7452dce1",
    "16827a11-a70d-4177-8fa1-95df4e2d822a",
    "c7e9c2e7-6b15-4594-ba0f-f9f0749d24fc",
    "f96e0a7b-0d4a-4cb3-8b90-1f2db178152e",
  ];

  const weekendHotels: string[] = [
    "d6964098-1214-4c50-a01b-d7092b5177e1",
    "b94820e2-c41b-4d19-931f-b39a4a7f7fb3",
    "bc73785a-f38a-44a0-b57f-0c1cf24e386a",
    "683cebad-d7a8-4b50-bd3d-3f409a0c296d",
  ];

  return (
    <div
      className="w100 h100"
      style={{
        minHeight: "100dvh",
        marginTop: isShortScreen ? "44px" : "65px",
      }}
    >
      <div className="container d-flex flex-column justify-content-center">
        <div
          className="d-flex flex-column white-color regular"
          style={{ paddingTop: "93px" }}
        >
          <p className="fs-h1-lg">
            {t("ds.title")}
            <br />
            <span className="primary-color">{t("ds.subtitle")}</span>
          </p>
        </div>
        <div className="content pt-4" style={{ paddingBottom: "93px" }}>
          <Search mode={isSmallScreen ? "mobile" : "desktop"} />
          <p className="mt-3 white-color fs-body">
            {t("ds.seconday_market1")}{" "}
            <span>
              <a
                onClick={() => {
                  fireTagManagerEvent("open_secondary_market", {
                    source: "home",
                  });
                  navigate(getLink(LINK_TYPE.SECONDARY_MARKET));
                }}
                className="underline white-color cursor-pointer fs-body"
              >
                {t("ds.seconday_market2")}
              </a>
            </span>
          </p>
        </div>
      </div>
      <div className="bg-white">
        <div className="d-flex flex-column">
          <div style={{ marginTop: "60px" }}></div>
          <div className="container">
            <p className="fs-h2 medium mb-3">{t("ds.most_wanted")}</p>
          </div>
          <div className="containerScroll" style={{ marginBottom: "40px" }}>
            <ScrollContainer
              isLightTheme={true}
              content={
                <>
                  <div className="d-flex gap-3 mx-3 mx-md-0">
                    {mostWanted.map((city, index) => (
                      <DiscoverCity
                        key={index}
                        title={city.title}
                        action={city.action}
                        image={city.image}
                      />
                    ))}
                  </div>
                </>
              }
            />
          </div>
          <div className="container">
            <Promo slot={promo}></Promo>
          </div>
          <div className="bg-black">
            <div style={{ marginTop: "40px", marginBottom: "30px" }}>
              <TakContainer slot={stays} isLightTheme={false} />
            </div>
            <div
              className="container"
              style={{ marginTop: "40px", marginBottom: "40px" }}
            >
              <StayRequest slot={stayRequest} />
            </div>
          </div>
          <div className="container" style={{ marginTop: "60px" }}>
            <p className="fs-h2 medium mb-3">{t("ds.best_city_mountain")}</p>
          </div>
          <div className="containerScroll">
            <DiscoverHotel hotelIdxs={bestHotels}></DiscoverHotel>
          </div>
          <div className="container" style={{ marginTop: "60px" }}>
            <p className="fs-h2 medium mb-3">{t("ds.discover_city")}</p>
          </div>
          <div className="containerScroll">
            <ScrollContainer
              isLightTheme={true}
              content={
                <>
                  <div className="d-flex gap-3 mx-3 mx-md-0">
                    {discoverCity.map((city, index) => (
                      <DiscoverCity
                        key={index}
                        title={city.title}
                        action={city.action}
                        image={city.image}
                      />
                    ))}
                  </div>
                </>
              }
            />
          </div>
          <div className="container" style={{ marginTop: "60px" }}>
            <p className="fs-h2 medium mb-3">{t("ds.discovercity")}</p>
          </div>
          <div className="containerScroll">
            <DiscoverHotel hotelIdxs={weekendHotels}></DiscoverHotel>
          </div>
          <div style={{ marginTop: "80px" }}></div>
        </div>
      </div>
    </div>
  );
}
