import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../config/Links";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { GetMetamatchLink } from "../../../assets/TakyonDesignSystem/components/StayRequest/MetamatchLink";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { COLLECTION_TYPE } from "../../../assets/models/iCollection";
import { iWallSlot, WALL_SLOT_TYPE } from "../../../config/wall";
import { NFT_SELL_STATUS } from "../../../assets/models/iNft";
import StayRequest from "../../../assets/TakyonDesignSystem/components/StayRequest/StayRequest";
import TakContainer from "../../../assets/TakyonDesignSystem/components/TakContainer/TakContainer";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";

export default function SecondaryMarket() {
  const { t } = useTranslation();
  const isShortScreen = useMediaQuery({
    query: "only screen and (min-width : 769px) and (max-height : 600px)",
  });
  const navigate = useNavigate();
  const mixedTakgetInspired = {
    $or: [
      {
        $and: [
          {
            $or: [
              { "payload.type": { $exists: false } },
              { "payload.type": "hotel" },
              { "payload.type": null },
            ],
          },
          {
            $or: [
              { isHotelFakeSupply: { $exists: false } },
              { isHotelFakeSupply: false },
            ],
          },
        ],
      },
      {
        $and: [
          {
            $or: [
              { "payload.type": { $exists: false } },
              { "payload.type": "hotel" },
              { "payload.type": null },
            ],
          },
          {
            isHotelFakeSupply: true,
          },
        ],
      },
    ],
    sellStatus: "FOR_SALE",
  };
  const isExperienceTak = {
    "payload.type": COLLECTION_TYPE.experience,
  };

  const stays: iWallSlot = {
    title: "secondary.stays_title",
    config: {
      type: WALL_SLOT_TYPE.CLASSIC,
      payload: {
        scroll: false,
        query: mixedTakgetInspired,
        sort: { isHotelFakeSupply: 1 },
        limit: 4,
      },
    },
  };
  const stayRequest: iWallSlot = {
    title: "secondary.stay_request_title",
    subtitle: "secondary.experiences_btn",
    config: {
      type: WALL_SLOT_TYPE.STAY_REQUEST,
      payload: {},
    },
  };
  const experiences: iWallSlot = {
    title: "secondary.experiences_title",
    config: {
      type: WALL_SLOT_TYPE.CLASSIC,
      payload: {
        scroll: true,
        query: {
          ...isExperienceTak,
          sellStatus: NFT_SELL_STATUS.FOR_SALE,
        },
        sort: { lockDate: -1 },
        limit: 4,
      },
    },
  };

  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  return (
    <div
      className="w100 h100"
      style={{
        minHeight: "100svh",
        marginTop: isShortScreen ? "44px" : "65px",
      }}
    >
      <div className=" h100">
        <div
          className="container d-flex flex-column justify-content-center"
          style={{
            marginTop: "143px",
            marginBottom: "80px",
          }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center pt-4 h1-large white-color regular text-center">
            <p
              className="fs-h1-lg"
              dangerouslySetInnerHTML={{ __html: t("secondary.title") }}
            ></p>
            <p className="primary-color fs-h1-lg">{t("secondary.subtitle")}</p>
            <div
              className="rounded-5 mt-4 mb-5"
              style={{
                padding: "5.021px",
                background:
                  "linear-gradient(93deg, #02FDD2 4.64%, #8CE786 98.99%)",
                width: "fit-content",
              }}
            >
              <Button
                size="sm"
                className="rounded-5 px-2 btn-light-simple"
                onClick={() => {
                  fireTagManagerEvent("metamatch_request");
                  // openSearchModal();
                  window.open(GetMetamatchLink(), "_blank");
                }}
                style={{
                  padding: isShortScreen ? "1px" : "6.021px",
                  backgroundColor: "white",
                  boxShadow: "3.011px 3.011px 3.011px 0px rgba(0, 0, 0, 0.20)",
                }}
                icon={"ai"}
                iconSize={15}
                variant="light"
                text={t("secondary.tellus_btn")}
              />
            </div>
          </div>
        </div>
        <div className="bg-white h100">
          <div className="container d-flex flex-column">
            <div style={{ marginTop: "20px" }}></div>
            <TakContainer isLightTheme={true} slot={stays} />
            <TakContainer isLightTheme={true} slot={experiences} />
            <StayRequest slot={stayRequest} />
            <div style={{ marginTop: "80px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
