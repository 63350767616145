import React from "react";

export default function SkeletonMap() {
  return (
    <div
      style={{
        flex: "1",
        height: "full",
        borderRadius: "10px",
        backgroundColor: "#e0e0e0",
        position: "relative",
        overflow: "hidden",
        animation: "pulse 1.5s infinite",
      }}
    ></div>
  );
}

// Add a keyframes animation for the pulse effect
const pulseAnimation = `
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
`;

// Inject animation into the document's <style> tag
const styleTag = document.createElement("style");
styleTag.innerHTML = pulseAnimation;
document.head.appendChild(styleTag);
