import React, { useState, useRef, useEffect, useCallback } from "react";

interface Props {
  min: number;
  max: number;
  onChange: (min: number, max: number) => void;
  onChangeEnd: (min: number, max: number) => void;
  values: { min: number; max: number };
}

export default function Slider({
  min,
  max,
  onChange,
  onChangeEnd,
  values,
}: Props) {
  const [_values, setValues] = useState<{ min: number; max: number }>(values);
  const [dragging, setDragging] = useState<"min" | "max" | null>(null);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setValues(values);
  }, [values]);

  const handleMove = useCallback(
    (clientX: number) => {
      if (dragging && sliderRef.current) {
        const slider = sliderRef.current;
        const sliderRect = slider.getBoundingClientRect();
        const newPercent = Math.min(
          Math.max(((clientX - sliderRect.left) / sliderRect.width) * 100, 0),
          100
        );
        const newValue = (newPercent / 100) * (max - min) + min;

        if (dragging === "min" && newValue <= _values.max * 0.9) {
          setValues((prevValues) => ({ ...prevValues, min: newValue }));
          onChange(newValue, _values.max);
        } else if (dragging === "max" && newValue >= _values.min * 1.15) {
          setValues((prevValues) => ({ ...prevValues, max: newValue }));
          onChange(_values.min, newValue);
        }
      }
    },
    [dragging, _values.min, _values.max, min, max, onChange]
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => handleMove(e.clientX),
    [handleMove]
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (e.touches.length > 0) {
        handleMove(e.touches[0].clientX);
      }
    },
    [handleMove]
  );

  const handleMouseUp = useCallback(() => {
    if (dragging) {
      onChangeEnd(_values.min, _values.max);
      setDragging(null);
    }
  }, [dragging, onChangeEnd, _values.min, _values.max]);

  const handleTouchEnd = handleMouseUp;

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleMouseMove, handleMouseUp, handleTouchMove, handleTouchEnd]);

  return (
    <div className="slider" ref={sliderRef}>
      <div className="base-line"></div>
      <div
        style={{
          marginLeft: `${((_values.min - min) / (max - min)) * 100}%`,
          width: `${((_values.max - _values.min) / (max - min)) * 100}%`,
        }}
        className="green-line"
      ></div>
      <div
        style={{
          marginLeft: `${((_values.min - min) / (max - min)) * 100}%`,
        }}
        className="ball min"
        onMouseDown={() => setDragging("min")}
        onTouchStart={() => setDragging("min")}
      ></div>
      <div
        style={{
          marginLeft: `${((_values.max - min) / (max - min)) * 100}%`,
        }}
        className="ball max"
        onMouseDown={() => setDragging("max")}
        onTouchStart={() => setDragging("max")}
      ></div>
    </div>
  );
}
