export function dateToSearchFormat(date: Date) {
  date = new Date(date);

  const y = date.getFullYear();
  const m = (date.getMonth() + 1).toString().padStart(2, "0");
  const d = date.getDate().toString().padStart(2, "0");

  return `${y}-${m}-${d}`;
}

export function calculateAverageInArray(array: number[]) {
  // Check if the array is not empty
  if (array.length === 0) return 0;

  // Sum all the elements in the array
  const sum = array.reduce((acc, number) => acc + number, 0);

  // Calculate the average
  const average = sum / array.length;

  return average;
}
