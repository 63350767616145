import getLink, { LINK_TYPE } from "../../../config/Links";

import takyonLight from "../../../assets/img/icons/TakyonLight.svg";
import facebook from "../../../assets/img/icons/facebook.svg";
import instagram from "../../../assets/img/icons/instagram.svg";
import linkedin from "../../../assets/img/icons/linkedin.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";

export interface footerItemsProps {
  path: string;
  text: string;
  icon?: string;
  target?: string;
  event?: string;
  event_params?: string;
}

export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const services: footerItemsProps[] = [
    { path: getLink(LINK_TYPE.WALL), text: t("footer.home") },
    {
      path: getLink(LINK_TYPE.SECONDARY_MARKET),
      text: t("footer.reselling"),
      event: "click_reselling",
      event_params: "footer",
    },
    {
      path: getLink(LINK_TYPE.FORCLIENTS),
      text: t("footer.forclients"),
      event: "click_how_works",
    },
    {
      path: getLink(LINK_TYPE.FORPARTNERS),
      text: t("footer.presskit"),
      event: "click_become_partner",
      event_params: "footer",
    },
  ];

  const follow: footerItemsProps[] = [
    {
      path: getLink(LINK_TYPE.INSTAGRAM),
      text: "Instagram",
      icon: instagram,
      target: "instagram",
    },
    {
      path: getLink(LINK_TYPE.LINKEDIN),
      text: "LinkedIn",
      icon: linkedin,
      target: "linkedin",
    },
    {
      path: getLink(LINK_TYPE.FACEBOOK),
      text: "Facebook",
      icon: facebook,
      target: "facebook",
    },
  ];

  const support: footerItemsProps[] = [
    { path: getLink(LINK_TYPE.SUPPORT), text: "FAQ", event: "click_support" },
    {
      path: "mailto:support@takyon.io",
      text: t("footer.contact"),
      event: "click_contact",
    },
    // { path: "mailto:info@takyon.io", text: "info@takyon.io" },
  ];

  const documents: footerItemsProps[] = [
    { path: getLink(LINK_TYPE.INNOVATIVE_STARTUP), text: t("footer.startup") },
    { path: "Whitepaper", text: "Whitepaper" },
    { path: getLink(LINK_TYPE.TOS), text: t("footer.tos") },
    { path: getLink(LINK_TYPE.PRIVACY), text: t("footer.privacy") },
    { path: getLink(LINK_TYPE.COOCKIES), text: t("footer.cookies") },
  ];

  const styles = {
    verticalSeparator: {
      width: "1px",
      height: "220px",
      background: "rgba(255, 255, 255, 0.2)",
      margin: "0 20px",
    },
  };

  return (
    <footer className="border-top">
      <section className="container black-background">
        <div className="content">
          <div className="py-5 px-2 pt-md-5 pb-md-4">
            <div className="d-flex flex-column flex-md-row gap-3">
              <div className="d-flex flex-column flex-md-row justify-content-between flex-grow-1 order-2 order-md-1">
                <hr className="light-grey-color mt-3 mb-4 d-md-none" />
                <div className="d-flex flex-column align-items-start white-color me-5">
                  <img src={takyonLight} alt="" className="width-100" />

                  <div className="height-16"></div>

                  <div className="d-flex flex-column">
                    <span className="fs-body light regular dark-grey-color">
                      Takyon S.r.l
                    </span>
                    <span className="fs-body light regular dark-grey-color">
                      Via Crema 15 - 20135,
                    </span>
                    <span className="fs-body light regular dark-grey-color mb-3">
                      Milano
                    </span>
                  </div>
                  <span className="fs-body light regular dark-grey-color">
                    P.IVA 12424180961
                  </span>
                </div>
                <hr className="light-grey-color my-4 d-md-none" />
                <div className="d-flex flex-column align-items-start white-color mb-md-0">
                  <span className="fs-body medium">Menu</span>

                  <div className="height-16"></div>

                  {services.map((service, index) => (
                    <div key={index}>
                      <a
                        onClick={() => {
                          if (service.event) {
                            if (service.event_params) {
                              fireTagManagerEvent(service.event, {
                                source: service.event_params,
                              });
                            } else {
                              fireTagManagerEvent(service.event);
                            }
                          }
                          navigate(service.path);
                        }}
                        className="fs-body light white-color text-decoration-none cursor-pointer"
                      >
                        {service.text}
                      </a>
                      <div className="height-8"></div>
                    </div>
                  ))}
                </div>
                <hr className="light-grey-color my-4 d-md-none" />
                <div className="d-flex flex-column align-items-start order-2 order-md-3 mb-4 mb-md-0">
                  <span className="fs-body medium white-color">
                    {t("footer.support")}
                  </span>

                  <div className="height-16"></div>

                  {support.map((support, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        support.event && fireTagManagerEvent(support.event)
                      }
                    >
                      <a
                        href={support.path}
                        className="fs-body light white-color text-decoration-none"
                      >
                        {support.text}
                      </a>
                      <div className="height-8 only-desktop"></div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className="vertical-separator d-none d-md-block order-md-2"
                style={styles.verticalSeparator}
              ></div>

              <div
                className="white-color ms-md-4 order-1 order-md-2"
                style={{ maxWidth: "400px" }}
              >
                <p className="medium mb-2 fs-h3">{t("footer.newsletter")}</p>
                <p className="fs-body light mb-4">
                  {t("footer.newsletterText")}
                </p>
                <div className="d-flex flex-md-row gap-2 justify-content-between w100">
                  {follow.map((follow, index) => (
                    <a
                      key={index}
                      className="border cursor-pointer w100 d-flex align-items-center justify-content-center"
                      style={{ padding: "10px 20px", borderRadius: "8px" }}
                      onClick={() => {
                        fireTagManagerEvent("click_social", {
                          channel: follow.path,
                        });
                        window.open(
                          follow.path,
                          "_blank",
                          "noopener,noreferrer",
                        );
                      }}
                    >
                      <img
                        src={follow.icon}
                        alt=""
                        style={{ width: "25px", height: "25px" }}
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <hr className="light-grey-color my-4" />

            <div className="d-flex flex-column flex-md-row align-items-start justify-content-md-between gap-md-5">
              <div className="d-flex flex-row justify-content-md-between gap-4 me-5">
                {follow.map((follow, index) => (
                  <div key={index}>
                    <a
                      className="dark-grey-color"
                      onClick={() => {
                        fireTagManagerEvent("click_social", {
                          channel: follow.path,
                        });
                        window.open(
                          follow.path,
                          "_blank",
                          "noopener,noreferrer",
                        );
                      }}
                    >
                      <img
                        src={follow.icon}
                        alt=""
                        style={{ width: "25px", height: "25px" }}
                      />
                    </a>
                  </div>
                ))}
              </div>

              <div className="height-16"></div>

              <div className="d-flex flex-column flex-md-row justify-content-md-between w100">
                {documents.map((document, index) => (
                  <div key={index}>
                    <a
                      href={document.path}
                      className="fs-body light light dark-grey-color text-decoration-none"
                    >
                      {document.text}
                    </a>
                    <div className="height-8 only-desktop"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}
