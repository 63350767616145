import { useTranslation } from "react-i18next";
import { iWallSlot } from "../../../../config/wall";
import Button from "../Button/Button";
import { FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { selectUser } from "../../../../redux/slices/userSlice";
import SwipeController from "../../../Controllers/SwipeController";
import { refreshSwipePage } from "../../../Utils/app";
import { apiErrorToast } from "../../../Utils/errors";
import { handleInputChange } from "../../../Utils/input";
import { closeModal, openModal } from "../../../Utils/modal";
import DatePicker from "../DatePicker/DatePicker";
import Input from "../Input/Input";
import Select from "../Select/Select";
import verifiedPng from "../../../../assets/img/offerbuy/verified.png";
import Icon from "../Icon/Icon";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../../assets/Utils/generic";
import { t } from "i18next";
import PrivacyController from "../../../Controllers/PrivacyController";
import { GetMetamatchLink } from "./MetamatchLink";
import { fireTagManagerEvent } from "../../../Services/tagmanager";

const TellUsMoreModal = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(""); // Optional phone number
  const [budget, setBudget] = useState("");
  const [numberOfPeople, setNumberOfPeople] = useState("");
  const [dates, setDates] = useState("");
  const [specialRequests, setSpecialRequests] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false); // For the required checkbox
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [typeOfSearch, setTypeOfSearch] = useState("");
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  useEffect(() => {
    // Form validation: Email, Budget, Number of People, Dates, and Checkbox must be valid
    if (
      email.trim() !== "" &&
      budget.trim() !== "" &&
      numberOfPeople.trim() !== "" &&
      dates.trim() !== "" &&
      checkboxChecked
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [
    email,
    budget,
    phone,
    typeOfSearch,
    numberOfPeople,
    dates,
    checkboxChecked,
  ]);

  const sendForm = async () => {
    setIsLoading(true);
    // save privacy info
    try {
      await PrivacyController.sendConsent({
        email: email,
        target: "StayRequestModal",
        consent: checkboxChecked,
      });
    } catch (error) {
      console.log(error);
    }

    try {
      await SwipeController.requestStay({
        email,
        phone, // Optional field
        typeOfSearch,
        budget,
        numberOfPeople,
        dates,
        specialRequests,
      });
      setIsFormSent(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isFormSent ? (
        <div className="d-flex flex-column h-100">
          <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
            <span style={{ height: "22px" }}></span>
          </div>
          <div className="height-24"></div>
          <div className="p-4 p-md-5 d-flex flex-column align-items-center justify-content-center h100 w100">
            <div className="height-10"></div>

            <span className="h2">{t("swipe.askcard.modal.thanks_title")}</span>
            <div className="height-10"></div>

            <span className="bodytext light">
              {t("swipe.askcard.modal.thanks_text")}
            </span>
            <div>
              <Button
                style={{ marginTop: "20px", marginBottom: "20px" }}
                text={t("swipe.askcard.modal.continue")}
                onClick={() => {
                  // Reset the form and close the modal or continue
                  setIsFormSent(false);
                  setEmail("");
                  setPhone("");
                  setBudget("");
                  setNumberOfPeople("1");
                  setDates("");
                  setSpecialRequests("");
                  setCheckboxChecked(false);
                  closeModal();
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
            <span className="bodytext medium">
              {t("swipe.askcard.modal.title")}
            </span>
          </div>
          <form
            className="modal-body"
            style={{
              overflow: "hidden",
            }}
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              sendForm();
            }}
          >
            <div
              className="modal-body"
              style={{
                maxHeight: isSmallScreen ? "calc(100dvh - 88px)" : "unset",
              }}
            >
              {/* Email */}
              <Form.Group className="modal-padding py-32 border-bottom">
                <Form.Label className="h4 medium">
                  {t("swipe.askcard.modal.6")}*
                </Form.Label>
                <Input
                  type="email"
                  placeholder={t("swipe.askcard.modal.61")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              {/* Phone Number (optional) */}
              <Form.Group className="modal-padding py-32 border-bottom">
                <Form.Label className="h4 medium">
                  {t("swipe.askcard.modal.8")}
                </Form.Label>
                <Input
                  type="text"
                  placeholder={t("swipe.askcard.modal.81")}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
              {/* Type of search */}
              <Form.Group className="modal-padding py-32 border-bottom">
                <Form.Label className="h4 medium">
                  {t("swipe.askcard.modal.searchtitle")}*
                </Form.Label>
                <Select
                  value={typeOfSearch}
                  onChange={(e) => setTypeOfSearch(e.target.value)}
                  options={[
                    {
                      value: "",
                      text: t("swipe.askcard.modal.search_placeholder"),
                    },
                    {
                      value: "trip",
                      text: t("swipe.askcard.modal.search_trip"),
                    },
                    {
                      value: "experience",
                      text: t("swipe.askcard.modal.search_experience"),
                    },
                    {
                      value: "other",
                      text: t("swipe.askcard.modal.search_other"),
                    },
                  ]}
                />
              </Form.Group>
              {/* Budget */}
              <Form.Group className="modal-padding py-32 border-bottom">
                <Form.Label className="h4 medium">
                  {t("swipe.askcard.modal.7")}*
                </Form.Label>
                <Input
                  type="text"
                  placeholder={t("swipe.askcard.modal.73")}
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                />
              </Form.Group>
              {/* Number of People */}
              <Form.Group className="modal-padding py-32 border-bottom">
                <Form.Label className="h4 medium">
                  {t("swipe.askcard.modal.71")}*
                </Form.Label>
                <Select
                  value={numberOfPeople}
                  onChange={(e) => setNumberOfPeople(e.target.value)}
                  options={[
                    { value: "", text: t("swipe.askcard.modal.72") },
                    { value: "1", text: "1 " + t("swipe.askcard.modal.74") },
                    { value: "2", text: "2 " + t("swipe.askcard.modal.75") },
                    { value: "3", text: "3 " + t("swipe.askcard.modal.75") },
                    { value: "4", text: "4 " + t("swipe.askcard.modal.75") },
                    { value: "5+", text: "5+ " + t("swipe.askcard.modal.75") },
                  ]}
                />
              </Form.Group>
              {/* Dates */}
              <Form.Group className="modal-padding py-32 border-bottom">
                <Form.Label className="h4 medium">
                  {t("swipe.askcard.modal.4")}*
                </Form.Label>
                <Input
                  type="text"
                  placeholder={t("swipe.askcard.modal.41")}
                  value={dates}
                  onChange={(e) => setDates(e.target.value)}
                />
              </Form.Group>
              {/* Special Requests */}
              <Form.Group
                className="modal-padding"
                style={{ paddingTop: "32px" }}
              >
                <Form.Label className="h4 medium">
                  {t("swipe.askcard.modal.90")}
                </Form.Label>
                <Input
                  type="text"
                  placeholder={t("swipe.askcard.modal.93")}
                  value={specialRequests}
                  onChange={(e) => setSpecialRequests(e.target.value)}
                />
              </Form.Group>
              {/* Checkbox (required) */}
              <Form.Group className="modal-padding">
                <div className="d-flex gap-2 mt-3 align-items-center">
                  <div>
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={checkboxChecked}
                      onChange={() => setCheckboxChecked(!checkboxChecked)}
                    />
                  </div>
                  <label htmlFor="checkbox" style={{ marginLeft: "8px" }}>
                    {t("swipe.askcard.modal.91")}
                  </label>
                </div>
              </Form.Group>
            </div>
            {/* Submit Button */}
            <div className="d-flex column justify-content-end gap-2 white-background px-4 py-2 border-top">
              <Button
                type="submit"
                text={t("swipe.askcard.modal.send")}
                disabled={!isFormValid}
                loading={isLoading}
              />
            </div>
          </form>
        </>
      )}
    </>
  );
};

interface Props {
  slot?: iWallSlot;
}

export default function StayRequest(props: Props) {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  return (
    <section
      className="gap-2 position-relative"
      style={{
        margin: "30px 0",
        padding: isSmallScreen ? "24px 40px" : "56px 40px",
        border: "2px solid var(--Gradient, #02FDD2)",
        background: "linear-gradient(135deg, #02FDD2 0%, #8CE786 100%)",
      }}
    >
      <div
        className={`text-white d-flex justify-content-between gap-3 ${
          isSmallScreen ? "flex-column align-items-start" : "align-items-center"
        }`}
      >
        <div className="d-flex flex-column flex-md-row gap-2 align-items-start align-items-md-center">
          <div
            className="d-flex justify-content-center align-items-center rounded-circle text-white"
            style={{
              minWidth: "60px",
              height: "60px",
              border: "2px solid #02FDD2",
              background: "white",
            }}
          >
            <Icon icon="ai" size={25} style={{ fill: "#000" }}></Icon>
          </div>
          <p className="fs-h4 medium m-0 black-color">
            {t(props.slot?.title ?? "")}
          </p>
        </div>
        <Button
          variant="light"
          text={t(props.slot?.subtitle ?? "")}
          onClick={() => {
            // openModal({
            //   content: <TellUsMoreModal />,
            // });
            fireTagManagerEvent("activate_metamatch");
            window.open(GetMetamatchLink(), "_blank");
          }}
        />
        {/* <p>{t(props.slot?.subtitle ?? "")}</p> */}
      </div>
    </section>
  );
}
