import { ButtonProps, Button as ReactBootstrapBtn } from "react-bootstrap";
import Icon from "../Icon/Icon";
import { useEffect, useState } from "react";

interface Props extends ButtonProps {
  icon?: string;
  loading?: boolean;
  text?: string | undefined;
  textClass?: string;
  textLeft?: boolean;
  responsive?: boolean;
  selected?: boolean;
  iconType?: "icon" | "symbol";
  iconStyle?: any;
  iconClass?: any;
  iconFill?: boolean;
  outline?: boolean;
  circle?: boolean;
  chip?: boolean;
  onlyText?: boolean;
  notification?: number | string;
  iconSize?: number;
  customIcon?: any;
}

export default function Button(props: Props) {
  const [iconOnly, setIconOnly] = useState(props.icon && !props.text);
  const [iconSize, setIconSize] = useState<number>(props.iconSize ?? 24);
  const [iconFill, setIconFill] = useState(props.iconFill);

  useEffect(() => {
    if (props.size === "lg" && iconOnly) setIconSize(30);
    if (typeof props.iconFill === "undefined") {
      setIconFill(props.selected);
    }
  }, [props.selected]);

  useEffect(() => {
    setIconFill(props.iconFill);
  }, [props.iconFill]);

  useEffect(() => {
    setIconOnly(props.icon && !props.text);
  }, [props.icon, props.text]);

  return (
    <ReactBootstrapBtn
      size={props.size}
      type={props.type}
      onClick={!props.loading && !props.disabled ? props.onClick : undefined}
      className={`
      ${iconOnly ? "btn-icon" : ""}
      ${props.responsive ? "btn-responsive" : ""}
      ${props.selected ? "btn-selected" : ""}
      ${props.outline ? "btn-outline" : ""}
      ${props.circle ? "btn-circle" : ""}
      ${props.chip ? "btn-chip" : ""}
      ${props.onlyText ? "btn-text" : ""}
      ${props.className}
       `}
      disabled={props.disabled}
      variant={props.variant}
      style={props.style}
    >
      {props.textLeft && props.text}
      {props.icon && !props.loading ? (
        <Icon
          style={props.iconStyle}
          className={props.iconClass}
          fill={iconFill}
          type={props.iconType ?? "symbol"}
          size={iconSize}
          icon={props.icon}
        />
      ) : null}
      {props.customIcon && !props.loading ? props.customIcon : null}
      <Icon
        size={props.loading ? undefined : 0}
        style={{ position: props.loading ? "unset" : "absolute", zIndex: 2 }}
        className="btn-icon-load"
        icon="progress_activity"
        type="symbol"
      />
      {props.notification ? (
        <div className="btn-notification">{props.notification}</div>
      ) : null}
      {!props.textLeft && props.text ? (
        <p className={`${props.textClass || "fs-body"} text-ellipsis`}>
          {!props.textLeft && props.text}
        </p>
      ) : null}
    </ReactBootstrapBtn>
  );
}
