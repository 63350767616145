import { ReactNode, useEffect, useRef, useState } from "react";
import Button from "../Button/Button";

export default function ScrollContainer({
  content,
  isLightTheme = false,
  arrowInMobile = false,
}: {
  content: ReactNode;
  isLightTheme?: boolean;
  arrowInMobile?: boolean;
}) {
  const [showArrows, setShowArrows] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = isLightTheme ? "dark-grey-color" : "white-color";

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft + container.offsetWidth < container.scrollWidth,
      );
    };

    handleScroll(); // Check on mount

    container.addEventListener("scroll", handleScroll);

    window.addEventListener("resize", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setInterval(() => {
      const container = containerRef.current;
      if (!container) return;

      const isMobile = window.innerWidth <= 768;
      setShowArrows(
        container.offsetWidth < container.scrollWidth &&
          (arrowInMobile || !isMobile),
      );
    }, 100);
  }, [arrowInMobile]);

  return (
    <div className="w100 d-flex align-items-center gap-1 position-relative">
      {showArrows && showLeftArrow && (
        <Button
          style={{ opacity: showLeftArrow ? 1 : 0, left: "-40px" }}
          onClick={() => {
            containerRef.current?.scrollBy({
              left: -200,
              behavior: "smooth",
            });
          }}
          icon="arrow_left_alt"
          variant="blank"
          size="sm"
          className={`${theme} position-absolute`}
        />
      )}
      <div
        style={{
          width: arrowInMobile ? "calc(100% - 10px)" : "100%",
          overflowX: "scroll",
        }}
      >
        <div ref={containerRef} className="multi-select-container gap-2">
          {content}
        </div>
      </div>
      {showArrows && showRightArrow && (
        <Button
          style={{ opacity: showRightArrow ? 1 : 0, right: "-40px" }}
          size="sm"
          icon="arrow_right_alt"
          variant="blank"
          className={`${theme} position-absolute`}
          onClick={() => {
            containerRef.current?.scrollBy({ left: 200, behavior: "smooth" });
          }}
        />
      )}
    </div>
  );
}
