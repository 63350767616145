import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { iWallConfig, iWallConfigObject } from "../../../config/wall";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";
import { set } from "lodash";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { useSelector } from "react-redux";
import { selectHeroVisibility } from "../../../redux/slices/appSlice";
import { motion } from "framer-motion";

export default function CatMenu(props: { menuCategories: iWallConfig }) {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentRoute = pathname.slice(1);
  const [showArrows, setShowArrows] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const isShortScreen = useMediaQuery({
    query: "only screen and (min-width : 769px) and (max-height : 600px)",
  });
  const buttonsContainer = useRef<HTMLDivElement>(null);
  const container = document.getElementById("cat-buttons-container");
  const isHeroVisible = false; //useSelector(selectHeroVisibility);

  const onButtonClick = (area: any, key: any) => {
    if (area === "all") {
      navigate("/");
      fireTagManagerEvent("category", { category_name: "explore_all" });
    } else {
      navigate("/" + area);
      fireTagManagerEvent("category", { category_name: area });
    }
  };

  useEffect(() => {
    const run = () => {
      if (!buttonsContainer.current) return;

      setShowArrows(
        buttonsContainer.current.scrollWidth >
          buttonsContainer.current.clientWidth
      );
    };

    if (buttonsContainer.current) {
      buttonsContainer.current.addEventListener("scroll", (e) => {
        if (buttonsContainer.current) {
          setShowLeftArrow(buttonsContainer.current.scrollLeft > 0);
          setShowRightArrow(
            buttonsContainer.current.scrollLeft +
              buttonsContainer.current.offsetWidth +
              5 <=
              buttonsContainer.current.scrollWidth
          );
        }
      });
    }
    const updateMenuPos = () => {
      let currentArea = currentRoute.split("/")[0];
      if (currentArea === "") currentArea = "all";
      if (currentArea) {
        const target = document.getElementById(currentArea);
        if (target && buttonsContainer.current) {
          const scrollPos =
            target.offsetLeft -
            buttonsContainer.current.offsetWidth / 2 +
            target.offsetWidth / 2;
          buttonsContainer.current.scrollTo({
            left: scrollPos,
            behavior: "smooth",
          });
        }
      }
    };

    setInterval(() => {
      run();
    }, 150);

    setTimeout(() => {
      updateMenuPos();
    }, 1000);
  }, []);

  const variants = {
    initial: {
      opacity: 0,
      y: -30,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "backOut",
        duration: 0.5,
        delay: 0.4,
      },
    },
  };

  return (
    <div
      // animate={isHeroVisible ? "open" : "closed"}
      className="w100 position-fixed black-background"
      style={{ zIndex: 199, top: isShortScreen ? "40px" : undefined }}
    >
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        // variants={{
        //   open: { opacity: 0, y: -30 },
        //   closed: { opacity: 1, y: 0 },
        //}}
        className={`container w100 mx-auto d-flex align-items-center gap-3 ${
          isShortScreen ? "py-1" : "pb-2 pt-3"
        } margin-menu`}
      >
        {showArrows && showLeftArrow ? (
          <Button
            style={{ opacity: showLeftArrow ? 1 : 0 }}
            onClick={() => {
              container?.scrollBy({ left: -200, behavior: "smooth" });
            }}
            icon="arrow_left_alt"
            variant="blank"
            size="sm"
            className="white-color "
          />
        ) : null}
        <div
          id="cat-buttons-container"
          className="d-flex gap-md-2 w100"
          style={{
            overflowX: "scroll",
          }}
          ref={buttonsContainer}
        >
          {props.menuCategories.map((btn: iWallConfigObject, key) => (
            <React.Fragment key={"btn_" + key}>
              <div id={btn.area}>
                <Button
                  style={{
                    ...(isSmallScreen && {
                      transform: "scale(0.9)",
                      marginRight: "-5px",
                    }),
                  }}
                  size="sm"
                  chip
                  variant="blank"
                  selected={
                    currentRoute === btn.area ||
                    (currentRoute === "" && btn.area === "all")
                  }
                  text={t(btn.categoryName)}
                  icon={btn.icon}
                  onClick={() => {
                    onButtonClick(btn.area, key);
                  }}
                />
              </div>
              {btn.separator && <div className="v-separator"></div>}
            </React.Fragment>
          ))}
        </div>

        {showArrows ? (
          <Button
            style={{ opacity: showRightArrow ? 1 : 0 }}
            size="sm"
            icon="arrow_right_alt"
            variant="blank"
            className="white-color "
            onClick={() => {
              container?.scrollBy({ left: 200, behavior: "smooth" });
            }}
          />
        ) : null}
      </motion.div>
    </div>
  );
}
