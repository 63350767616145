import { useTranslation } from "react-i18next";
import helpers from "../../../../_Web/helpers/helpers";

export default function TakPrice({
  price,
  oldPrice,
  currency = "€",
}: {
  price: number;
  oldPrice?: number;
  currency?: string;
}) {
  const { t } = useTranslation();

  return (
    <>
      {helpers.price(price, currency)}
      {oldPrice !== undefined && oldPrice > price && (
        <span
          className="fs-body regular dark-grey-color"
          style={{ textDecoration: "line-through", marginLeft: "8px" }}
        >
          {helpers.price(oldPrice, currency, 2)}
        </span>
      )}
    </>
  );
}
