// Skeleton Card Component
const SkeletonCard = ({ isxLargeScreen }: { isxLargeScreen: boolean }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: isxLargeScreen ? "row" : "column",
        border: "1px solid #ddd",
        borderRadius: "10px",
        padding: "15px",
        backgroundColor: "#fff",
        gap: "15px",
      }}
    >
      <div
        style={{
          flex: "0 0 190px",
          height: "190px",
          borderRadius: "20px",
          backgroundColor: "#e0e0e0",
          animation: "pulse 1.5s infinite",
        }}
      ></div>
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "5px",
        }}
      >
        <div
          style={{
            height: "30px",
            backgroundColor: "#e0e0e0",
            borderRadius: "5px",
            animation: "pulse 1.5s infinite",
          }}
        ></div>
        <div
          style={{
            height: "30px",
            width: "60%",
            backgroundColor: "#e0e0e0",
            borderRadius: "5px",
            animation: "pulse 1.5s infinite",
          }}
        ></div>
        <div
          style={{
            height: "30px",
            backgroundColor: "#e0e0e0",
            borderRadius: "5px",
            animation: "pulse 1.5s infinite",
          }}
        ></div>
        <div
          style={{
            height: "30px",
            width: "40%",
            backgroundColor: "#e0e0e0",
            borderRadius: "5px",
            animation: "pulse 1.5s infinite",
          }}
        ></div>
      </div>
      <div
        style={{
          flex: "0 0 150px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div
          style={{
            height: "15px",
            width: "60%",
            backgroundColor: "#e0e0e0",
            borderRadius: "5px",
            animation: "pulse 1.5s infinite",
          }}
        ></div>
        <div
          style={{
            width: "80%",
            height: "30px",
            backgroundColor: "#e0e0e0",
            borderRadius: "5px",
            animation: "pulse 1.5s infinite",
          }}
        ></div>
      </div>
    </div>
  );
};

// Main Component to Render Skeletons
export default function ResultSkeletonList({
  isxLargeScreen,
}: {
  isxLargeScreen: boolean;
}) {
  // Simulate loading multiple skeleton cards
  const skeletons = Array.from({ length: 5 });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: isxLargeScreen ? "63vw" : "100vw",
        overflow: "hidden",
      }}
    >
      {skeletons.map((_, index) => (
        <SkeletonCard isxLargeScreen={isxLargeScreen} key={index} />
      ))}
    </div>
  );
}

// Add a keyframes animation for the pulse effect
const pulseAnimation = `
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
`;

// Inject animation into the document's <style> tag
const styleTag = document.createElement("style");
styleTag.innerHTML = pulseAnimation;
document.head.appendChild(styleTag);
