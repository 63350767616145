import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import getLink, { LINK_TYPE } from "../../../config/Links";
import { useTranslation } from "react-i18next";
import PrivacyController from "../../../assets/Controllers/PrivacyController";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";

export const COOKIES_ACCEPTED_KEY =
  "WV5B7436BV34RCQ" + process.env.REACT_APP_FRONTEND_URI;
export const COOKIES_REFUSED_KEY =
  "CV2365B4768N574" + process.env.REACT_APP_FRONTEND_URI;

const isProd = process.env.REACT_APP_ROBOTS_POLICY === "all";

export const initCookies = () => {
  const isAccepted = window.localStorage.getItem(COOKIES_ACCEPTED_KEY);
  // const isRefused = window.localStorage.getItem(COOKIES_REFUSED_KEY);

  // else onlyEssentialCookies();
};

export default function CookiesBanner() {
  const { t } = useTranslation();
  const [cookiesAccepted, setCookiesAccepted] = useState(true);
  const user = useSelector(selectUser);

  const onAcceptCoockies = async (accepted: boolean) => {
    // save privacy info
    try {
      await PrivacyController.sendConsent({
        email: user?.email ? user.email : "",
        target: "AcceptCoockies",
        consent: accepted,
      });
    } catch (error) {
      console.log(error);
    }

    window.localStorage.setItem(COOKIES_ACCEPTED_KEY, accepted.toString());
    setCookiesAccepted(true);
    // onlyEssentialCookies();
  };

  useEffect(() => {
    const isAccepted = window.localStorage.getItem(COOKIES_ACCEPTED_KEY);
    const isRefused = window.localStorage.getItem(COOKIES_REFUSED_KEY);

    setCookiesAccepted(isAccepted || isRefused ? true : false);
  }, []);

  if (cookiesAccepted) return <></>;

  return (
    <div style={{ zIndex: 1000 }} className="position-fixed bottom-0 w100 p-3">
      <Container>
        <div className="d-flex flex-column flex-md-row gap-4 p-4 white-background rounded shadow">
          <div
            onClick={() => {
              setCookiesAccepted(true);
              // onlyEssentialCookies();
            }}
            className="cursor-pointer primary-background d-flex justify-content-center align-items-center width-25 height-25 rounded-circle"
          >
            x
          </div>

          <div className="text">
            <p>{t("cookie.text")}</p>
            <u>
              <Link to={getLink(LINK_TYPE.COOCKIES)}>{t("cookie.policy")}</Link>
            </u>
          </div>

          <div className="d-flex flex-column gap-3">
            <Button
              text={t("cookie.accept")}
              className="w100"
              size="sm"
              onClick={() => {
                onAcceptCoockies(true);
              }}
            />
            <Button
              text={t("cookie.refuse")}
              className="w100"
              size="sm"
              variant="secondary"
              onClick={() => {
                onAcceptCoockies(false);
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
