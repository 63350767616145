import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import { iNft, NFT_SELL_STATUS } from "../../../models/iNft";
import Stars from "../Stars/Stars";
import TakLogo from "../../images/TakyonShortLight.svg";
import Icon from "../Icon/Icon";
import LikeButton from "../Button/LikeButton";
import { useSelector } from "react-redux";
import { isFavorite, selectUser } from "../../../../redux/slices/userSlice";
import helpers from "../../../../_Web/helpers/helpers";
import {
  getAmountOfGuests,
  getCityFromLocation,
  getCityShortFromLocation,
  getCountryFromLocation,
  getRegionFromLocation,
} from "../../../Utils/location";
import getLink, { LINK_TYPE } from "../../../../config/Links";
import { openModal } from "../../../Utils/modal";
import Share from "../Share/Share";
import TakOffersReceived from "./TakOffersReceived";
import TakPrice from "./TakPrice";
import TakShortId from "./TakShortId";
import { ReactNode, useEffect, useState } from "react";
import { COLLECTION_TYPE } from "../../../models/iCollection";
import {
  datetimeRangeToString,
  datetimeToString,
  formatMinutes,
} from "../../../Utils/generic";
import { openNftDetailPage } from "../../../../_Web/pages/TakPage/TakPage";
import { getTranslationExperience } from "../../../Utils/langutils";
import { fireTagManagerEvent } from "../../../Services/tagmanager";

export function NftTypeTag({ type }: { type?: string }) {
  const { t } = useTranslation();

  return (
    <div className="tag tag-black bodytext regular">
      {type === COLLECTION_TYPE.hotel || !type ? (
        <>
          <Icon
            size={24}
            icon="bed"
            fill
            style={{ fill: "white", color: "white" }}
          ></Icon>
          <span className="bodytext-sm regular">{t("wall.classic.stay")}</span>
        </>
      ) : null}

      {type === COLLECTION_TYPE.experience ? (
        <>
          <Icon
            size={20}
            icon="nordic_walking"
            fill
            style={{ fill: "white", color: "white" }}
          ></Icon>
          <span className="bodytext-sm regular">
            {t("wall.classic.experience")}
          </span>
        </>
      ) : null}
    </div>
  );
}

interface Props {
  nft: iNft;
  small?: boolean;
  preventAspectRatio?: boolean;
  style?: React.CSSProperties;
  addon?: ReactNode;
  isFavorite?: boolean;
}

/**
 * A component that renders a card for a single NFT.
 * @param {{ nft: iNft; preventAspectRatio?: boolean; style?: React.CSSProperties; addon?: ReactNode; small?: boolean }} props
 * @returns {JSX.Element}
 */

export default function TakCard({
  nft,
  preventAspectRatio = false,
  style,
  addon,
  small,
}: Props) {
  const { t, i18n } = useTranslation();
  const user = useSelector(selectUser);
  // user is equal to nft._owner
  const isOwner = user?.email === nft?.owner;
  const type: COLLECTION_TYPE | undefined = nft?.payload?.type;
  const [expName, setExpName] = useState<string>("");
  const likeButtonVisible = user === undefined || !isOwner;

  let bgImage =
    nft?.images?.[0] ??
    nft?._collectionImages?.[0] ??
    nft?._collectionCensus?.images?.[0];

  if (bgImage && bgImage.startsWith("http://res.cloudinary.com")) {
    const startIndex = bgImage.indexOf("upload/") + 7;
    const endIndex = bgImage.indexOf("/", startIndex);

    const newUrl =
      bgImage.substring(0, startIndex) +
      "f_auto/w_1226" +
      bgImage.substring(endIndex);

    bgImage = newUrl;
  }

  useEffect(() => {
    if (!nft || !nft?.payload?.category) return;
    if (type !== COLLECTION_TYPE.experience) return;

    const exp = getTranslationExperience(nft, i18n);
    if (!exp) return;

    setExpName(exp.name);
  }, [nft, type, i18n.language]);

  if (!nft)
    return (
      <div
        style={{ border: "1px dashed darkgrey", width: "inherit" }}
        className="panel nft aspect-ratio"
      ></div>
    );

  let oldPrice = helpers.getOldPrice(nft);
  let currentPrice = helpers.getCurrentPrice(nft);

  //console.log("nft", nft);

  return (
    <div
      style={{ ...style }}
      onClick={() => {
        fireTagManagerEvent("open_card", {
          nft_id: nft._id,
          source:
            window.location.pathname === "/"
              ? "home"
              : window.location.pathname,
        });
        openNftDetailPage(nft._id);
      }}
      // className={`panel nft cursor-pointer ${
      //   preventAspectRatio ? "" : "aspect-ratio"
      // }`}
      className={"panel nft cursor-pointer p-0 bg-white"}
    >
      <div
        className="panel-header"
        style={{ height: "192px", padding: "13px 16px" }}
      >
        <div
          className="panel-bg"
          style={{
            height: "192px",
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="panel-shadow" style={{ height: "192px" }}></div>
        {/* {small && <NftTypeTag type={nft?.payload?.type} />} */}
        <div
          className="d-flex flex-column justify-content-between h100 "
          style={{ zIndex: "1" }}
        >
          {/* Tak ID */}
          {nft?.shortId && (
            <div className="d-flex">
              <TakShortId shortId={nft?.shortId} />
            </div>
          )}
          {/* Tak Owner */}
          <div
            style={{ maxWidth: "170px" }}
            className="m-0 d-flex align-items-center gap-2 black-color bg-white rounded p-1 px-2"
          >
            <Icon
              size={22}
              icon="check_circle"
              fill
              style={{ fill: "#4282FF", color: "#4282FF", minWidth: "22px" }}
            ></Icon>
            {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE ? (
              <span className="fs-body-xs regular text-nowrap text-ellipsis">
                {t("wall.owner")}{" "}
                {nft?.fakeOwnerName
                  ? nft?.fakeOwnerName
                  : nft?._ownerName
                  ? nft?._ownerName
                  : null}
              </span>
            ) : null}
          </div>
        </div>
        {/* {!small && <TakOffersReceived nft={nft} />} NOTE: Commented out because for now values are 0 */}
        {/* <div
          className="gap-2 w-100 justify-content-end" // NOTE: added  w-100 and justify-content-end for now values are 0
          style={{ display: "inline-flex" }}
        >
          <Button
            className="btn btn-light-simple"
            circle
            iconSize={20}
            size="sm"
            icon="ios_share"
            onClick={(e) => {
              e.stopPropagation();
              fireTagManagerEvent("open_share");
              openModal({
                content: (
                  <Share
                    id={String(nft?._id)}
                    link={getLink(LINK_TYPE.TAK, "API", {
                      __NFTID__: String(nft?._id),
                    })}
                  />
                ),
              });
            }}
          />
          {likeButtonVisible && (
          <LikeButton//NOTE: Commented out because for now values are 0
            nftId={nft._id}
            likeCount={nft?.likesCount ? nft.likesCount : 0}
          />)}
        </div> */}
      </div>
      <div className="panel-bottom" style={{ padding: "20px 24px" }}>
        <div className="panel-body">
          <div className="w-100">
            {/* <div className="mb-2">
              {!small && <NftTypeTag type={nft?.payload?.type} />}
            </div> */}
            <div className="fs-body black-color">
              <div style={{ width: "98%" }}>
                <p className="m-0 medium text-nowrap text-ellipsis">
                  {type === COLLECTION_TYPE.hotel || !type ? (
                    <>
                      {getCityFromLocation(nft._collectionCensus?.location)} (
                      {getCityShortFromLocation(
                        nft._collectionCensus?.location,
                      )}
                      , {getRegionFromLocation(nft._collectionCensus?.location)}
                      ,{" "}
                      {getCountryFromLocation(nft._collectionCensus?.location)})
                    </>
                  ) : null}

                  {type === COLLECTION_TYPE.experience ? (
                    <>{expName ?? nft?.payload?.category}</>
                  ) : null}
                </p>
              </div>
              <div className="m-0 d-flex align-items-center gap-1 w100">
                {type === COLLECTION_TYPE.hotel || !type ? (
                  <>
                    <p
                      className="text-nowrap text-ellipsis"
                      style={{ maxWidth: "200px" }}
                    >
                      {nft._collectionCensus?.name}
                    </p>

                    {nft._collectionCensus?.stars ? (
                      <>
                        <Stars stars={nft._collectionCensus?.stars} />
                      </>
                    ) : null}
                  </>
                ) : null}

                {type === COLLECTION_TYPE.experience ? (
                  <p
                    className="text-nowrap text-ellipsis"
                    style={{ maxWidth: "200px" }}
                  >
                    {t("wall.duration")}:{" "}
                    {formatMinutes(nft?.payload?.duration)}
                  </p>
                ) : null}
              </div>

              {type === COLLECTION_TYPE.hotel || !type ? (
                <>
                  <p className="m-0">
                    {datetimeRangeToString(
                      nft?.payload?.checkin!,
                      nft?.payload?.checkout!,
                    )}
                  </p>
                  {!small && (
                    <p className="m-0">
                      {getAmountOfGuests(nft).adults +
                        getAmountOfGuests(nft).childs +
                        " " +
                        t("wall.guests")}
                    </p>
                  )}
                </>
              ) : null}

              {type === COLLECTION_TYPE.experience ? (
                <>
                  {datetimeToString(
                    nft?.payload?.date,
                    nft._collectionCensus?.location?.gmt,
                    false,
                    true,
                  )}
                </>
              ) : null}
            </div>
          </div>
          {!small && (
            <div>
              <p className="fs-h3 medium black-color">
                {nft.currentPrice && (
                  <TakPrice
                    price={Number(currentPrice)}
                    oldPrice={Number(oldPrice)}
                  />
                )}
              </p>
            </div>
          )}
        </div>
      </div>
      <div
        className="panel-footer px-4 border-top bodytext small light justify-content-center align-items-center"
        style={{ paddingTop: "12px", paddingBottom: "12px" }}
      >
        <Button text={t("nftpage.offer")} className="w100" />
      </div>
    </div>
  );
}
