import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectPendingOrders,
  setPendingOrders,
} from "../../../redux/slices/orderSlice";
import OrderController from "../../../assets/Controllers/OrderController";
import UserController from "../../../assets/Controllers/UserController";
import { apiErrorToast } from "../../../assets/Utils/errors";
import { iNft, DEFAULT_OVERPRICE } from "../../../assets/models/iNft";
import { iBuyOrder } from "../../../assets/models/iOrder";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";

import { useAppDispatch } from "../../../redux/redux";
import { useTranslation } from "react-i18next";

import verified from "../../../assets/img/offerbuy/verified.png";
import {
  closeModal,
  openModal,
  setModalVariables,
} from "../../../assets/Utils/modal";
import { secondsToFormat } from "../../../assets/Utils/generic";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import { unset } from "lodash";

const stripe = loadStripe(String(process.env.REACT_APP_STRIPE_KEY));

function CheckoutSuccessPage({ nft }: { nft: iNft }) {
  const { t } = useTranslation();

  return (
    <div className="p-3 d-flex flex-column align-items-center justify-content-center modal-body">
      <div className="d-flex justify-content-center align-items-center">
        <Icon icon="check_circle" type="symbol" size={200} />
      </div>

      <div className="height-32"></div>

      <p className="m-0 h4 regular">{t("app.checkout.successtitle")}</p>

      <div className="height-12"></div>

      <p className="m-0 dark-grey-color bodytext-regular text-center">
        {t("app.checkout.successtext")}
      </p>

      <div className="height-32"></div>

      <div className="d-flex flex-column-reverse flex-md-row gap-2">
        <Button
          text={t("app.checkout.view")}
          onClick={() => {
            closeModal();
          }}
        />
      </div>
    </div>
  );
}

interface CheckoutProps {
  nft: iNft;
  onSuccess: Function;
}

function CheckoutForm({ nft, onSuccess }: CheckoutProps) {
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [error, setError] = useState("");
  const [order, setOrder] = useState<iBuyOrder>();

  const orders = useSelector(selectPendingOrders);

  const { t } = useTranslation();

  const getClientSecret = async () => {
    setIsLocaleLoading(true);

    try {
      const response = await OrderController.createPaymentIntent({
        nftId: nft._id,
      });
      await OrderController.initPendingOrders();
      setClientSecret(response.client_secret);
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  useEffect(() => {
    const order = orders.find((o) => o.nftId === nft._id);

    if (order) {
      setOrder(order);
      setClientSecret(order.paymentIntent.client_secret);
    } else getClientSecret();

    // eslint-disable-next-line
  }, [orders]);

  const cancelSubmit = async (e: any) => {
    e.preventDefault();
    setIsLocaleLoading(true);

    try {
      if (order) {
        closeModal();
        await OrderController.deletePendingOrder({ orderId: order._id });
        await OrderController.initPendingOrders();
      }
    } catch (error) {
      apiErrorToast(error);
    }

    setIsLocaleLoading(false);
  };

  const paySubmit = async (e: any) => {
    e.preventDefault();
    setIsLocaleLoading(true);

    try {
      const payload = await stripe?.confirmCardPayment(clientSecret, {
        payment_method: {
          // eslint-disable-next-line
          card: elements!.getElement(CardElement)!,
        },
      });

      if (payload?.error) setError(t("app.checkout.failed"));
      else await onSuccess();
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  return (
    <div className="flex-grow-1">
      <form
        className="checkout-form d-flex flex-column justify-content-between h-100"
        onSubmit={paySubmit}
        onReset={cancelSubmit}
      >
        <div className="modal-padding">
          <CardElement
            onChange={(e) => {
              setError(e.error ? e.error.message : "");
            }}
          />
        </div>
        <div>
          <div className="d-flex justify-content-between border-top modal-padding py-2">
            <Button
              variant="blank"
              onlyText={true}
              text={t("app.order.cancel")}
              loading={isLocaleLoading}
              type="reset"
              onClick={() => {
                OrderController.closeAllPendingOrders();
                closeModal();
              }}
            />
            <Button
              loading={isLocaleLoading}
              type="submit"
              text={t("app.checkout.pay")}
            />
          </div>
          <p className="text-danger">{error}</p>
        </div>
      </form>
    </div>
  );
}

export function StripeCheckout({ nft, onSuccess }: CheckoutProps) {
  const { t } = useTranslation();
  const locale = t("app.checkout.stripe_locale")
    ? t("app.checkout.stripe_locale")
    : ("en" as any);

  return (
    <Elements stripe={stripe} options={{ locale }}>
      <CheckoutForm onSuccess={onSuccess} nft={nft} />
    </Elements>
  );
}

export function WalletCheckout({ nft }: { nft: iNft }) {
  const [, setIsLoading] = useState(false);

  const [credits, setCredits] = useState<number>(0);
  const [order, setOrder] = useState<iBuyOrder>();

  const orders = useSelector(selectPendingOrders);

  const [times, setTimes] = useState<Array<number>>([]);
  const [intervalId, setIntervalId] = useState<any>();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const loadCredits = async () => {
    setIsLoading(true);
    try {
      const creditsBalance = await UserController.getCreditsBalance();
      setCredits(creditsBalance);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadCredits();
  }, []);

  useEffect(() => {
    const order = orders.find((o) => o.nftId === nft!._id);
    if (order) setOrder(order);
  }, [orders]);

  // useEffect(() => {
  //   setModalVariables({ hideModalCloseBtn: true });
  // }, [order, credits, times, intervalId]);

  useEffect(() => {
    clearInterval(intervalId);

    const tid = setInterval(() => {
      orders.forEach((o, key) => {
        setTimes((times) => {
          const t =
            (new Date(o.expiresAt).getTime() - new Date().getTime()) / 1000;
          times[key] = t;

          return [...times];
        });
      });
    }, 1000);

    setIntervalId(tid);

    return () => {
      clearInterval(tid);
    };
  }, [orders]);

  useEffect(() => {
    const filteredOrders = orders.filter((o) => {
      const t = new Date(o.expiresAt).getTime() - new Date().getTime();
      if (t <= 0) {
        closeModal();
        fireTagManagerEvent("timeout_payment_intent", { item_id: o.nftId });
      }
      return t >= 0;
    });
    dispatch(setPendingOrders(filteredOrders));
  }, [times]);

  return (
    <Elements stripe={stripe}>
      <div className="modal-body" style={{ maxHeight: "unset" }}>
        <>
          <div className="h-100 d-flex flex-column">
            <div>
              <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
                <span style={{ height: "22px" }}></span>
                {orders.map((_, key) => {
                  return (
                    <p
                      key={key}
                      className="bodytext-sm regular m-0 only-desktop"
                    >
                      {t("app.order.timeleft")}: {secondsToFormat(times[key])}
                    </p>
                  );
                })}
              </div>
              <h4 className="modal-padding py-32">
                {t("app.checkout.completepayment")}
              </h4>
              <div className="modal-padding">
                <div
                  style={{
                    padding: "12px 24px",
                    boxShadow: "4px 3px 10px 1px rgba(0, 0, 0, 0.05)",
                  }}
                  className="light-grey-background rounded"
                >
                  <p className="m-0 bodytext-sm medium">
                    {t("app.checkout.checkout")}
                  </p>
                  <div className="height-12"></div>
                  <div className="d-flex justify-content-between">
                    <p className="m-0 bodytext-sm light">
                      {t("app.checkout.price")}
                    </p>
                    <p className="m-0 bodytext-sm light">
                      €{order?.amount.toFixed(2)}
                    </p>
                  </div>
                  <div className="height-12"></div>
                  <div className="d-flex justify-content-between">
                    <p className="m-0 bodytext-sm light">
                      {t("app.checkout.servicefee")}
                    </p>
                    <p className="m-0 bodytext-sm light">
                      €{(order?.amount! * DEFAULT_OVERPRICE).toFixed(2)}
                    </p>
                  </div>
                  <div className="height-12"></div>
                  {credits > 0 ? (
                    <div className="d-flex justify-content-between">
                      <p className="m-0 bodytext-sm light">
                        {t("app.checkout.creditsdiscount")}
                      </p>
                      <p className="m-0 bodytext-sm light">
                        - €
                        {(credits > order?.amount!
                          ? order?.amount!
                          : credits
                        ).toFixed(2)}
                      </p>
                    </div>
                  ) : null}
                  <div className="height-12"></div>
                  <div
                    style={{ margin: "0" }}
                    className="horizontal-divider"
                  ></div>
                  <div className="height-12"></div>
                  <div className="d-flex justify-content-between">
                    <p className="m-0 bodytext-sm medium">
                      {t("app.checkout.checkout")}
                    </p>
                    <p className="m-0 bodytext-sm medium">
                      €{Number(order?.amountToPay).toFixed(2)}
                    </p>
                  </div>
                  <div className="height-12"></div>
                  <p className="m-0 text-center bodytext-xs light dark-grey-color">
                    {t("app.checkout.paymentsecure")}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex flex-column h-100">
                <div className="modal-padding">
                  <div className="height-24"></div>
                  <p className="m-0 bodytext-sm medium">
                    {t("app.checkout.card")}
                  </p>
                  <div className="height-8"></div>
                </div>
                <StripeCheckout
                  nft={nft}
                  onSuccess={async () => {
                    fireTagManagerEvent("buy", {
                      nft_id: nft._id,
                      amount: order?.amount,
                    });
                    dispatch(setPendingOrders([]));
                    openModal({ content: <CheckoutSuccessPage nft={nft} /> });
                  }}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </Elements>
  );
}
