interface Props {
    scale?: number;
    light?: boolean;
    small?: boolean;
  }
  
  export default function Loader({ scale, light, small }: Props) {
    return (
      <div
        style={{
          transform: `scale(${scale ?? 1})`,
        }}
        className={`loader ${light ? "light" : ""}
        ${small ? "small" : ""}`}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
  