import { t } from "i18next";
import { iNft } from "../models/iNft";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const x_SMALL_SCREEN = "(max-width: 375px)";
export const SMALL_SCREEN = "(max-width: 768px)";
export const LARGE_SHORT_SCREEN = "(min-width: 769px) and (max-height: 600px)"; // Antonio
export const LARGE_SCREEN = "(min-width: 769px) and (min-height: 700px)";
export const x_LARGE_SCREEN = "(min-width: 1280px)";
export const xx_LARGE_SCREEN = "(min-width: 1536px)";
export const contentMaxWidth = "1200px";
export const contentMaxWidthNumber = 1200;

export function getAmountOfNights(startDate: any, endDate: any) {
  try {
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();

    const timeDifference = endDateTime - startDateTime;
    const numberOfNights = Math.round(timeDifference / oneDay);

    return Number(numberOfNights);
  } catch (error) {
    console.log(error);
    return 0;
  }
}

export function getPricePerNight(nft: iNft) {
  const amount = getAmountOfNights(nft.payload?.checkin, nft.payload?.checkout);
  const price = nft.currentPrice ?? nft.marketPrice ?? nft.originalPrice;

  return Number(price) / Number(amount);
}

const DAY_DICT = (index: number) => {
  return [
    t("weekshort.sunday"),
    t("weekshort.monday"),
    t("weekshort.tuesday"),
    t("weekshort.wednesday"),
    t("weekshort.thursday"),
    t("weekshort.friday"),
    t("weekshort.saturday"),
  ][index];
};
export const MONTH_DICT = (index: number) => {
  return [
    t("month.january"),
    t("month.february"),
    t("month.march"),
    t("month.april"),
    t("month.may"),
    t("month.june"),
    t("month.july"),
    t("month.august"),
    t("month.september"),
    t("month.october"),
    t("month.november"),
    t("month.december"),
  ][index];
};

export const datetimeRangeToString = (
  isodate1: any,
  isodate2: any,
  gmt = -new Date().getTimezoneOffset(),
  week: boolean = true
) => {
  if (!isodate1 || !isodate2) return undefined;

  const _startDate = new Date(isodate1);
  const _endDate = new Date(isodate2);

  const localOffset = -new Date().getTimezoneOffset();
  const delta = gmt - localOffset; // minutes

  const startDate = new Date(
    _startDate.setMinutes(_startDate.getMinutes() + delta)
  );
  const endDate = new Date(_endDate.setMinutes(_endDate.getMinutes() + delta));

  const startDayOfWeek = week ? DAY_DICT(startDate.getDay()) : "";
  const endDayOfWeek = week ? DAY_DICT(endDate.getDay()) : "";

  if (startDate.getMonth() === endDate.getMonth()) {
    // Same month
    return `${
      week ? startDayOfWeek : ""
    } ${startDate.getDate()} - ${endDayOfWeek} ${endDate.getDate()} ${MONTH_DICT(
      startDate.getMonth()
    )}`;
  } else {
    // Different months
    return `${week ? startDayOfWeek : ""} ${startDate.getDate()} ${MONTH_DICT(
      startDate.getMonth()
    )} - ${endDayOfWeek} ${endDate.getDate()} ${MONTH_DICT(
      endDate.getMonth()
    )}`;
  }
};

export const datetimeToString = (
  isodate: any,
  gmt: number = -new Date().getTimezoneOffset(),
  short = false,
  time = false,
  year = true,
  month = false
) => {
  if (!isodate) return undefined;

  const genDate = new Date(isodate);

  const localOffset = -new Date().getTimezoneOffset();
  const delta = gmt - localOffset; // minutes

  const date = new Date(genDate.setMinutes(genDate.getMinutes() + delta));

  let result = `${date.getDate()} ${t(
    MONTH_DICT(date.getMonth())
  )} ${date.getFullYear()}`;

  if (short) {
    const fullyear = date.getFullYear() % 100;
    if (year)
      result = `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${fullyear}`;
    else
      result = `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}`;
  }

  if (time) {
    result += ` ${
      date.getHours().toString().length === 1
        ? `0${date.getHours()}`
        : date.getHours()
    }:${
      date.getMinutes().toString().length === 1
        ? `0${date.getMinutes()}`
        : date.getMinutes()
    }`;
  }

  if (month) {
    result = `${t(MONTH_DICT(date.getMonth()))}`;
  }

  return result;
};

export function isNumber(input: any): boolean {
  input = Number(input);
  return typeof input === "number" && !isNaN(input);
}

export function secondsToFormat(seconds: number) {
  if (isNaN(seconds) || seconds < 0) return "00:00:00";
  const hour = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, "0");
  const min = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const sec = Math.floor(seconds % 60)
    .toString()
    .padStart(2, "0");
  return `${hour}:${min}:${sec}`;
}

export function isPhoneNumber(p: string) {
  // eslint-disable-next-line
  // var phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  // var digits = p.replace(/\D/g, "");
  // return phoneRe.test(digits);

  return p.length >= 5;
}

export function calculateCommonWordPercentage(str1: string, str2: string) {
  // Split the input strings into arrays of words
  const words1 = str1.toLowerCase().split(/\s+/);
  const words2 = str2.toLowerCase().split(/\s+/);

  // Calculate the total number of words in the longer string
  const totalWords = Math.max(words1.length, words2.length);

  // Count how many words they have in common
  const commonWords = words1.filter((word) => words2.includes(word));

  // Calculate the percentage of common words
  const percentage = (commonWords.length / totalWords) * 100;

  return Number(percentage.toFixed(2)); // Return the percentage with 2 decimal places
}

export function calculateAmountOfCommonElementInArray(
  arr1: string[],
  arr2: string[]
) {
  arr1 = arr1.map((e) => e.toLowerCase());
  arr2 = arr2.map((e) => e.toLowerCase());

  let count = 0;

  for (let i = 0; i < arr1.length; i++) {
    const el = arr1[i];

    if (arr2.includes(el)) count += 1;
  }

  return count;
}

export function shuffleArray(array: any[]) {
  array = structuredClone(array);

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function isWeekend(date: any): boolean {
  date = new Date(date);
  const dayOfWeek = date.getDay();
  return dayOfWeek === 5 || dayOfWeek === 6;
}

export function getRandomNumberBetween(
  min: number,
  max: number,
  float?: boolean
) {
  if (float) return Math.random() * (max - min) + min;

  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomString(len: number, charSet?: string) {
  charSet =
    charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var randomString = "";
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
}

export function closestFriday(date: any): Date {
  date = new Date(date);

  const dayOfWeek = date.getDay(); // Get the current day of the week (0-6, where 0 is Sunday and 6 is Saturday)
  let daysToAdd = 5 - dayOfWeek; // Calculate days to add to get the next Friday (5 represents Friday)

  if (daysToAdd > 0) {
    // If daysToAdd is positive, the closest Friday is ahead of the given date
    date.setDate(date.getDate() + daysToAdd);
  } else if (daysToAdd < 0) {
    // If daysToAdd is negative, the closest Friday is behind, so we add 7 to get the previous Friday
    date.setDate(date.getDate() + (7 + daysToAdd));
  }
  // If daysToAdd is 0, the given date is already a Friday, so no changes are needed

  return date;
}

export function combineArraysPattern<T, U>(
  array1: T[],
  array2: U[],
  X: number,
  Y: number
): (T | U)[] {
  let result: (T | U)[] = [];
  let array2Index = 0;

  for (let i = 0; i < array1.length; i++) {
    result.push(array1[i]);
    // Check if it's time to insert elements from array2 (every Y elements of array1)
    if ((i + 1) % Y === 0 && array2Index < array2.length) {
      // Insert X elements from array2 into the result
      for (let j = 0; j < X && array2Index < array2.length; j++) {
        result.push(array2[array2Index++]);
      }
    }
  }

  // Append any remaining elements from array2 if not all elements were inserted
  while (array2Index < array2.length) {
    result.push(array2[array2Index++]);
  }

  return result;
}

export function cloudinaryToJpg(image: string): string {
  if (!image.includes("res.cloudinary.com") || !image.includes(".png"))
    return image;

  return image.replace(".png", ".jpg");
}

export function toggleScrolling(bool: boolean) {
  if (bool) {
    document.querySelector("html")!.style.overflowY = "scroll";
  }
  if (!bool) {
    document.querySelector("html")!.style.overflow = "hidden";
  }
}

export function generateRandomName(): string {
  // Expanded lists of Italian first names and last initials.
  const firstNames: string[] = [
    "Luca",
    "Giovanni",
    "Francesco",
    "Alessandro",
    "Andrea",
    "Lorenzo",
    "Matteo",
    "Gabriele",
    "Riccardo",
    "Davide",
    "Marco",
    "Antonio",
    "Giuseppe",
    "Salvatore",
    "Mario",
    "Luigi",
    "Pietro",
    "Domenico",
    "Carlo",
    "Vincenzo",
    "Mark",
    "John",
    "Sarah",
    "Emily",
    "Michael",
    "Laura",
    "David",
    "Jessica",
    "Steve",
    "Rachel",
  ];
  const lastNames: string[] = [
    "Rossi",
    "Russo",
    "Ferrari",
    "Esposito",
    "Bianchi",
    "Romano",
    "Colombo",
    "Ricci",
    "Marino",
    "Greco",
    "Bruno",
    "Gallo",
    "Conti",
    "De Luca",
    "Costa",
    "Giordano",
    "Mancini",
    "Rizzo",
    "Lombardi",
    "Moretti",
    "Smith",
    "Johnson",
    "Williams",
    "Jones",
    "Brown",
    "Davis",
    "Miller",
    "Wilson",
    "Moore",
    "Taylor",
  ];

  // Select a random first name and a random last name's first initial
  const firstName: string =
    firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName: string =
    lastNames[Math.floor(Math.random() * lastNames.length)];

  return `${firstName} ${lastName[0]}.`;
}

export function formatMinutes(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = "";

  if (hours > 0) {
    result += `${hours}h `;
  }

  if (remainingMinutes > 0 || hours === 0) {
    result += `${remainingMinutes}'`;
  }

  return result.trim();
}

export function scrollTo(id: string) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }
}
